import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";
import styled from "styled-components";

const Page = styled.div`
  margin: 150px 0 0 0;
`;

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <Page>
      <p>Loading</p>
    </Page>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `metamorphoses`,
  linkResolver:
    ({ node, key, value }) =>
    (doc) => {
      // 	// Your link resolver
      if (doc.uid === "home") {
        return `/`;
      } else if (doc.type === "conversation") {
        return `/conversations/${doc.uid}`;
      } else if (doc.type === "collaboration") {
        return `/collaborations/${doc.uid}`;
      } else if (doc.type === "object") {
        return `/objects/${doc.uid}`;
      } else if (doc.type === "splash") {
        return `/splash`;
      } else {
        return `/${doc.uid}`;
      }
    },
});
